//base regagent and filing fees
const regagent_fee = 100;
const filing_fee = 79;
const totalfees = regagent_fee + filing_fee;
let htmltoinsert = '';
let possibleexpeditepricing = '';

// wording used to describe speed of filing
var expediteWording = {
    L0: 'Normal Processing',
    L1: 'Expedited Processing',
    L2: 'Expedited Processing',
    L3: 'Expedited Processing',
    C0: 'Normal Processing',
    C1: 'Expedited Processing',
    C2: 'Expedited Processing',
    C3: 'Expedited Processing'
};
// end wording used to describe speed of filing

//load state pricing information
var statepricing = (function () {
    var json = null;
    $.ajax({
        'async': false,
        'global': false,
        'url': "/inc/pricing.php",
        'dataType': "json",
        'success': function (data) {
            json = data;
        }
    });
    return json;
})();
// end load state pricing information

// convert state from/to abbr or name
function convert_state(name, to) {
    var states = new Array(
        {'name':'District Of Columbia', 'abbrev':'DC'}, {'name':'Alabama', 'abbrev':'AL'},          {'name':'Alaska', 'abbrev':'AK'},
        {'name':'Arizona', 'abbrev':'AZ'},              {'name':'Arkansas', 'abbrev':'AR'},         {'name':'California', 'abbrev':'CA'},
        {'name':'Colorado', 'abbrev':'CO'},             {'name':'Connecticut', 'abbrev':'CT'},      {'name':'Delaware', 'abbrev':'DE'},
        {'name':'Florida', 'abbrev':'FL'},              {'name':'Georgia', 'abbrev':'GA'},          {'name':'Hawaii', 'abbrev':'HI'},
        {'name':'Idaho', 'abbrev':'ID'},                {'name':'Illinois', 'abbrev':'IL'},         {'name':'Indiana', 'abbrev':'IN'},
        {'name':'Iowa', 'abbrev':'IA'},                 {'name':'Kansas', 'abbrev':'KS'},           {'name':'Kentucky', 'abbrev':'KY'},
        {'name':'Louisiana', 'abbrev':'LA'},            {'name':'Maine', 'abbrev':'ME'},            {'name':'Maryland', 'abbrev':'MD'},
        {'name':'Massachusetts', 'abbrev':'MA'},        {'name':'Michigan', 'abbrev':'MI'},         {'name':'Minnesota', 'abbrev':'MN'},
        {'name':'Mississippi', 'abbrev':'MS'},          {'name':'Missouri', 'abbrev':'MO'},         {'name':'Montana', 'abbrev':'MT'},
        {'name':'Nebraska', 'abbrev':'NE'},             {'name':'Nevada', 'abbrev':'NV'},           {'name':'New Hampshire', 'abbrev':'NH'},
        {'name':'New Jersey', 'abbrev':'NJ'},           {'name':'New Mexico', 'abbrev':'NM'},       {'name':'New York', 'abbrev':'NY'},
        {'name':'North Carolina', 'abbrev':'NC'},       {'name':'North Dakota', 'abbrev':'ND'},     {'name':'Ohio', 'abbrev':'OH'},
        {'name':'Oklahoma', 'abbrev':'OK'},             {'name':'Oregon', 'abbrev':'OR'},           {'name':'Pennsylvania', 'abbrev':'PA'},
        {'name':'Rhode Island', 'abbrev':'RI'},         {'name':'South Carolina', 'abbrev':'SC'},   {'name':'South Dakota', 'abbrev':'SD'},
        {'name':'Tennessee', 'abbrev':'TN'},            {'name':'Texas', 'abbrev':'TX'},            {'name':'Utah', 'abbrev':'UT'},
        {'name':'Vermont', 'abbrev':'VT'},              {'name':'Virginia', 'abbrev':'VA'},         {'name':'Washington', 'abbrev':'WA'},
        {'name':'West Virginia', 'abbrev':'WV'},        {'name':'Wisconsin', 'abbrev':'WI'},        {'name':'Wyoming', 'abbrev':'WY'} 
    );
    
    var returnthis = false;
    $.each(states, function (index, value) {
        if (to == 'name') {
            if (value.abbrev.toLowerCase() == name.toLowerCase()) {
                returnthis = value.name;
                return false;
            }
        } else if (to == 'abbrev') {
            if (value.name.toLowerCase() == name.toLowerCase()) {
                returnthis = value.abbrev.toUpperCase();
                return false;
            } else {
                returnthis = name;
            }
        }
    });
    return returnthis;
}
// end convert state from/to abbr or name

function updateHiddenForm() {
    $('#itemprice').val($('#expediteSpeed option:selected').attr('itemprice'));
    $('#itemdesc').val($('#expediteSpeed option:selected').attr('desc'));
    $('#expeditelevel').val($('#expediteSpeed option:selected').attr('expeditelevel'));
    $('#statename').val($('#stateDropDown').val());
    $('#cartdiv').val($('#cartitems').html());
}

function updateShoppingCart() {
    $('.orderfor').html($('#stateDropDown').val() + " " + $('#entityDropDown').val());
    updateTotal();

}

function updateTotal() {
    var sumofallprices = 0;
    for (var i = 0; i < $('select').find(':selected').length; i++) {
        $this = $('select').find(':selected')[i];
        if (parseInt($($this).attr('itemprice'), 10) > 0) {
            sumofallprices += parseInt($($this).attr('itemprice'), 10);
        }

    }
    $('.carttotal').html("$" + sumofallprices);
    $('#totalprice').val(sumofallprices);
    $('.totalText').text('$'+sumofallprices);
    renderCartContents();
    updateOpts();

}

function updateOpts() {
    var opts = "";
    for (var i = 0; i < $('select').find(':selected').length; i++) {
        $this = $('select').find(':selected')[i];
        if ($($this).attr('opt')) {
            opts += $($this).attr('opt');
        }

    }
    $('#opts').val(opts);
}


function renderCartContents() {
    $('#cartitems').html("")
    for (var i = 0; i < $('select').find(':selected').length; i++) {
        $this = $('select').find(':selected')[i];
        if (parseInt($($this).attr('itemprice'), 10) > 0) {
            addCartItem($($this).attr('itemdesc'), parseInt($($this).attr('itemprice'), 10))
        }

    }
    addCartItem("<hr>", "<hr>")
    addCartItem("<b>Total:</b>", "<b>$" + $('#totalprice').val() + "</b>")
    $('#cartdiv').val($('#cartitems').html());
}

function addCartItem(itemdesc, itemprice) {

    var cartitem = '<tr><td><table cellpadding="0" cellspacing="0" border="0" width="88"><tbody><tr><td class="eight">' + itemdesc + '</td></tr></tbody></table></td><td align="right" style="text-align:right;" class="eight">' + itemprice + '</td></tr>'
    $('#cartitems').html($('#cartitems').html() + cartitem)


}

$(document).ready(function () {

    var url = window.location.href;

    if (url.indexOf("pay") == -1 && url.indexOf("step2") == -1) {
        $('select').change(function () {
            updateTotal()
        });
    }

    var optionFEINSelection = {
        no: 'no',
        Yes: 'Yes - hasSSN'
    };

    $('#optionFEIN').change(function () {
        optionFEINSelection[$('#optionSCORP').val()] = $(this).val();
    });


    $('#copyAddr').change(function () {
        if ($(this).is(':checked')) {
            $('.billingAddr').each(function (i) {

                $(this).val($('[data-target="' + $(this).attr('name') + '"]').val());
            });

        } else {
            $('.billingAddr').each(function (i) {
                $(this).val("");
            });
        }
    });


    $('#optionSCORP').change(function () {

        $('#optionFEIN').find('[value="' + optionFEINSelection[$(this).val()] + '"]').attr('selected', 'selected');
        if ($('#optionSCORP').val() == "Yes") {
            $('#optionFEIN').prop('disabled', 'disabled');
        } else {
            $('#optionFEIN').removeAttr("disabled");
        }

        updateTotal();
        renderCartContents();
    });


    $('#formId').val("incMe" + Math.floor((Math.random() * 1000000) + 1));


    //populate state dropdown
    $.each(statepricing, function (index, value) {
        $('#stateDropDown').append('<option value="' + convert_state(index, "abbrev") + '">' + convert_state(index, "name") + '</option>');
    });
    //end populate state dropdown

    // ENTITY SELECTION AND PRICING
    $("#entityDropDown, #stateDropDown").change(function () {
        updatePricing();
    });
    $("#expediteSpeed").change(function () {
        updatePriceText();
        updateHiddenForm();
        updateShoppingCart();
    });

    function updatePricing() {
        $('#expediteSpeed').find('option').remove().end()
        if ($("#stateDropDown").val() && $("#entityDropDown").val()) {

            possibleexpeditepricing = statepricing[convert_state($("#stateDropDown").val(), "abbrev")][$("#entityDropDown").val()]

            for (var i = 0; i < possibleexpeditepricing.length; i++) {

                $('#expediteSpeed').append('<option value="' + i + '" expeditelevel="' + possibleexpeditepricing[i]["ExpeditedCode"] + '" itemdesc="' + expediteWording[possibleexpeditepricing[i]["ExpeditedCode"]] + " - " + possibleexpeditepricing[i]["FileDate"] + ' day" itemprice="' + (parseInt(possibleexpeditepricing[i]["TotalProcessPrice"], 10) + parseInt(totalfees, 10)) + '"">' + expediteWording[possibleexpeditepricing[i]["ExpeditedCode"]] + ' - ' + possibleexpeditepricing[i]["FileDate"] + ' day $' + (parseInt(possibleexpeditepricing[i]["TotalProcessPrice"], 10) + parseInt(totalfees, 10)) + '</option>');

            }
            updatePriceText();
            updateHiddenForm();
            updateShoppingCart();
        }

    }

    function updatePriceText() {
        $('.stateText').text($('#stateDropDown :selected').text());
        $('.entityText').text(function(){
            if($('#entityDropDown').val() == 'Corp') {
                return 'Corporation';
            } else {
                return $('#entityDropDown').val();
            }
        });
        $('.expiditeText').text( $('[name="expediteSpeed"] option:selected').attr('itemdesc') );
    }

    if($('[name="preselectedState"]').val() != '') {
        $('#stateDropDown option[value="' + $('[name="preselectedState"]').val() + '"]').attr('selected', true);
        $('#stateDropDown').trigger('change');
    }

    // END ENTITY SELECTION AND PRICING


    //All Officers the Same

    $('.allthesame').change(function(){
        var fname = $('[name="president-first_name"]').val();
        var lname = $('[name="president-last_name"]').val();

        if( $(this).is(':checked') ) {
 
            $('[name="secretary-first_name"]').val(fname);
            $('[name="secretary-last_name"]').val(lname);
            $('[name="treasurer-first_name"]').val(fname);
            $('[name="treasurer-last_name"]').val(lname);

        } else {
            $('[name="secretary-first_name"]').val('');
            $('[name="secretary-last_name"]').val('');
            $('[name="treasurer-first_name"]').val('');
            $('[name="treasurer-last_name"]').val('');

        }

    })



    //Show more members and mangers
    $('.morePeople').hide();


    $('.addPerson p').click(function() {
        if( $('.addPerson p').hasClass('show') ) {

            $('.morePeople').show();
            $(this).removeClass('show').addClass('remove');;
            $('.addremove').text('Remove');
            $('.plus').text('-');

        } else {
            $('.morePeople').hide();
            $(this).removeClass('remove').addClass('show');;
            $('.addremove').text('Add');
            $('.plus').text('+');
            $('.morePeople input').val('');
        }


    });

});